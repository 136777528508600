<template>
    <div
        class="user-rating-stars"
        :class="{ 'floated' : floated }"
        :title="type_tip"
    >
        <span
            class="user-rating-star"
            v-for="i in [1,2,3,4,5]"
            :key="'star'+i"
            @mouseover="rating_hover = i"
            @mouseleave="rating_hover = 0"
            @click.stop="setRating({
                new_rating: i,
                current_rating: detail[rating_type],
                route_id: detail.id,
            })"
        >
            <b-icon
                class="is-centered is-small"
                :class="{ 'has-text-primary' : rating_hover }"
                :icon="ratingStar(i)"
            ></b-icon>
        </span>
    </div>
</template>
<script>
import {
    mapActions,
} from 'vuex';

export default {
    name: 'RatingStars',
    props: {
        detail: Object,
        rating_type: String,
        floated: Boolean,
    },
    data() {
        return {
            rating_hover: 0,
            type_tips: {
                avg_rating: 'Average rating',
                rating: 'Your rating',
            },
        };
    },
    computed: {
        type_tip() {
            return `${this.type_tips[this.rating_type]}`;
        },
    },
    methods: {
        ...mapActions('route', [
            'setRating',
        ]),
        // work out the rating star value
        ratingStar(i) {
            let is_solid = false;
            // handle hover
            if (this.rating_hover) {
                is_solid = this.rating_hover >= i;
            } else if (this.detail[this.rating_type]) {
                is_solid = this.detail[this.rating_type].toFixed(0) >= i;
            }
            return is_solid ? 'star' : 'star-outline';
        },
    },
};
</script>

<style lang="scss" scoped>
    .user-rating-stars {
        &.floated {
            position: absolute;
            right: 0.5em;
            top: 0.5em;
            border-radius: 2em;
            background-color: $white;
            padding: 0 0.5em;
        }

        .icon {
            cursor: pointer;
            :before {
                font-size: 14px;
            }
        }
    }
</style>
